<template>
  <section id="scan">
    <qrcode-stream
      :camera="camera"
      @decode="onDecode"
      @init="onInit"
    >
      <div
        v-if="validationSuccess"
        class="validation-success"
      >
        Harap menunggu, sedang memvalidasi
      </div>
      <div
        v-if="validationFailure"
        class="validation-failure"
      >
        Domain URL/QR tidak dikenal!
      </div>
      <div
        v-if="validationPending"
        class="validation-pending"
      >
        Long validation in progress...
      </div>
    </qrcode-stream>
    <div class="input-code">
      <v-row>
        <v-col
          cols="8"
        >
          <v-text-field
            v-model="qrcode"
            label="Masukkan Kode QR Manual"
            outlined
            clearable
            @keyup.enter="cekQr()"
          />
          <!-- <input type="text" v-model="qrcode" style="text-transform: uppercase; border: #fff solid 2px;width: 100%; height: 50px;border-radius: 5px; padding: 5px; color: #fff"  placeholder="Masukkan kode"> -->
        </v-col>
        <v-col
          cols="4"
        >
          <v-btn
            color="primary"
            width="100%"
            height="55px"
            @click="cekQr()"
          >
            CEK
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="alert"
      style="position: fixed; top: 27vh"
    >
      <v-alert
        type="error"
        dismissible
      >
        {{ message }}
      </v-alert>
    </div>
  </section>
</template>
<script>
  import { QrcodeStream } from 'vue-qrcode-reader'

  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    components: { QrcodeStream },

    data () {
      return {
        isValid: undefined,
        camera: 'auto',
        result: null,
        qrcode: '',
        alert: false,
        message: null,
        type: undefined,
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      validationPending () {
        return this.isValid === undefined &&
          this.camera === 'off'
      },

      validationSuccess () {
        return this.isValid === true
      },

      validationFailure () {
        return this.isValid === false
      },
    },
    methods: {
      onInit (promise) {
        promise
          .catch(console.error)
          .then(this.resetValidationState)
      },

      resetValidationState () {
        this.isValid = undefined
      },

      async onDecode (content) {
        this.result = content
        this.turnCameraOff()

        // pretend it's taking really long
        await this.timeout(3000)
        this.isValid = content.startsWith('https://fitsea.co.id')

        if (this.isValid === true) {
          window.location = this.result
        }

        // some more delay, so users have time to read the message
        await this.timeout(2000)

        this.turnCameraOn()
      },

      turnCameraOn () {
        this.camera = 'auto'
      },

      turnCameraOff () {
        this.camera = 'off'
      },

      timeout (ms) {
        return new Promise(resolve => {
          window.setTimeout(resolve, ms)
        })
      },

      cekQr: function () {
        // router.push('check/'+this.route)
        if (this.qrcode == '') {
          this.alert = true
          this.message = 'Oops, anda belum memasukkan kode QR'
        } else if (this.qrcode.length <= 8) {
          this.alert = true
          this.message = 'Oops, kode QR setidaknya 8 karakter'
        } else {
          if (this.qrcode.startsWith('BY')) {
            this.$router.push({ path: 'Check', query: { code: this.qrcode } })
          } else if (this.qrcode.startsWith('RW')) {
            this.$router.push({ path: 'Reward', query: { code: this.qrcode } })
          } else {
            this.alert = true
            this.message = 'Kode tidak Valid, harap perhatikan besar/kecil huruf yang anda masukkan'
          }
        }
      },
    },
  }
</script>
<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}
.qrcode-stream-wrapper {
    height: 80vh;
}

.input-code {
    position: fixed;
    top: 10vh;
    padding: 25px 15px 0px 15px;
    width: 100%;
    background-color: rgba(41, 41, 41, .8);
}
.input-code input#input-40 {
  text-transform: uppercase;
}

</style>
